import Head from "next/head";

const Custom404 = () => {
  return (
    <div>
      <Head>
        <title>404</title>
      </Head>
      <div className="relative h-screen overflow-hidden">
        <div className=" container absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
          <div className="m-auto text-center lg:col-10">
            <div>
              <h1 className="mb-10 text-[100px] font-bold">404</h1>
              <a
                rel="nofollow"
                href="/"
                className="cursor-pointer rounded-lg border-2 border-arrivalhub-main bg-arrivalhub-main py-4 px-8"
              >
                Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Custom404;
